import React from 'react'
import Header from '../components/Header'
import Whats from '../components/Whats'

function about() {
  return (
    <div className="container">
    <Header/>
    <Whats/>
    </div>
  )
}

export default about