import React from 'react';
import './Whats.scss';
import { StaticImage } from "gatsby-plugin-image";
import otterGIF from '../../images/lawer.gif';
import { Link } from "gatsby";

function Whats() {
  return (
    <div className='wrapper wrapper_about'>
        <div className='snippet'>
            <div className='snippet_icon'>
            <img src={otterGIF} alt="Otter dancing with a fish" />
            </div>
            <div className='snippet_text'>
                <h1>Привет, это Юркнопка!🎉</h1>
                <p>Мы пытаемся сделать процесс создания юридических документов легче, быстрее и дешевле.
                    <br></br>
                   Все, что Вам нужно - это заполнить форму. После этого шага Вы получите готовый документ с инструкцией как подать его в суд.
                   <br></br>
                   Стоимость документа указана в шапке виджета Юркнопка и не превышает 200 рублей.
                </p>
                <div className='image-wrapper'>
                   <StaticImage src="../../images/prev.png" alt="A prev" />
                </div>
                <Link className="btn btn-blue" to="/showcases">Смотреть примеры!</Link>
            </div>
        </div>
    </div>
  )
}

export default Whats